import React from 'react';
import './card.scss';
import { NavLink  } from 'react-router-dom';

const Card = (props) => {
    const { img, title, shortExcerpt, url } = props.content;
    const { index, spanSub } = props;
    return (
        <div className={`card animate__animated ${spanSub?  'b-m-0':''}`} style={{"--index":index}}>
            <div className="body">
                {(url !== undefined && url !== '') && 
                <NavLink to={url || ''}>
                    <div className="image">
                        <img src={img} alt={img}/>
                    </div>
                    {title && <h4>{title}</h4>}
                    {shortExcerpt && <p className="sub" dangerouslySetInnerHTML={{__html: shortExcerpt}}></p>}
                </NavLink>
                }
                {(url === undefined || url === '') && 
                <>
                    <div className="image">
                        <img src={img} alt={img}/>
                    </div>
                    {title && <h4>{title}</h4>}
                    {shortExcerpt && <p className="sub" dangerouslySetInnerHTML={{__html: shortExcerpt}}></p>}
                </>
                }
            </div>
            {url && url !=='' && 
            <div className="footer">
                <NavLink className="sub" to={url}>VIEW MORE</NavLink>
            </div>
            }
        </div>
    );
}

export default Card;