import React from 'react';
import './modal.scss';
import { useRouteMatch, useHistory } from 'react-router-dom';
import SimpleBar from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css';

const Modal = (props) => {
  const { goBack } = useHistory();
  const match = useRouteMatch('/results/:id')

  return (
    <>
    {match !== null && <div className="modal-wrapper">
      <div className="modal">
        <div className="inner">
          <div className="close" onClick={()=> {
            goBack();
          }}></div>
          <SimpleBar className="content">
          {props.children}  
          </SimpleBar>
        </div>
      </div>
      <div className="backdrop" onClick={() => {
        goBack();
      }}></div>
    </div>}
    </>
  )
}

export default Modal;