import React, { Component } from 'react';
import './password-protect.scss';

class PasswordProtect extends Component {
    constructor(props) {
        super(props);
        
        this.p = 'ROANA';
        this.cookieValidTime = 86400 * 1000;
        this.cookieName = 'mrpass';

        this.inputRef = React.createRef();

        this.state = {
            password: '',
            valid: false,
            reset: true,
            ready: false
        }
    }
    onSubmit(evt) {
        console.log(evt.keyCode)
        if (evt.keyCode == 13) {
            if (this.p == this.state.password) {
                this.setState({
                    valid: true,
                    reset: false
                });
            } else {
                this.setState({
                    valid: false,
                    reset: false
                });
            }
        }
    }
    handleChange(event) {
        this.setState({
            password: event.target.value
        });
    }
    componentDidMount() {
        this.inputRef.current.onanimationend = () => {
            this.setState({
                ready: true
            });
        }

        let cookies = document.cookie.split(';');
        let loginCookie = cookies.find((c) => {
            return c.split('=')[0].trim() == this.cookieName;
        });
        
        if (loginCookie != undefined) {
            let lastTime =  parseInt(loginCookie.split('=')[1].trim());
            let diff = (new Date().getTime()) - lastTime;
            if (diff <= this.cookieValidTime) {
                this.props.onValid(true);
            }
        }
    }
    componentDidUpdate(prevState) {
        if (prevState.valid !== this.state.valid && !this.state.reset) {
            if (this.state.valid) {
                this.inputRef.current.onanimationend = () => {
                    // write cookie
                    document.cookie = `${this.cookieName}=${new Date().getTime()}`;
                    this.props.onValid(true);
                }
            } else {
                // nothing
                this.inputRef.current.onanimationend = () => {
                    this.setState({
                        reset: true
                    });
                }
            }
        }
    }
    render() {
        const { valid, reset, ready } = this.state;
        return (
            <div className="password-protect animate__animated">
                <img src="/img/password-welcome-logo-new.png" />
                <input type="password"
                    placeholder="ENTER PASSWORD" 
                    disabled={valid}
                    className={`animate__animated ${ready?'ready':''} ${!reset && !valid ? 'not-valid':''} ${valid ? 'valid':''}`} 
                    ref={this.inputRef}
                    onChange={this.handleChange.bind(this)} 
                    onKeyDown={this.onSubmit.bind(this)}></input>
            </div>
        );
    }
}

export default PasswordProtect;